import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Divider } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { FormEvent, useState } from 'react';
import { BottomNavAction } from '../../components/custom/BottomNavAction';
import { BottomNavBar } from '../../components/custom/BottomNavBar';
import { ButtonGroupControl } from '../../components/custom/ButtonGroupControl';
import { ConfirmationDialog } from '../../components/custom/ConfirmationDialog';
import { RadioButtonControl } from '../../components/custom/RadioButtonControl';
import { TextFieldOptional } from '../../components/custom/TextFieldOptional';
import { TextFieldRequired } from '../../components/custom/TextFieldRequired';
import { SStackForm } from '../../components/styled/stack/SStackForm';
import { SStackWhite } from '../../components/styled/stack/SStackWhite';
import { BookLanguage, BookLevel, LibraryBook } from './LibraryDtos';

interface EditBookProps {
  libraryBook: LibraryBook
  onBack: () => void
  onDelete: (libraryBook: LibraryBook) => void
  onUpdated: (libraryBook: LibraryBook) => void
  logout: () => void
}

export function EditBook(props: EditBookProps) {
  const [bookName, setBookName] = useState<string>(props.libraryBook.bookName);
  const [author, setAuthor] = useState<string>(props.libraryBook.author);
  const [language, setLanguage] = useState<string>(props.libraryBook.language);
  const [level, setLevel] = useState<string>(props.libraryBook.level);
  const [bigLetters, setBigLetters] = useState<boolean>(props.libraryBook.bigLetters);
  const [colorBook, setColorBook] = useState<boolean>(props.libraryBook.colorBook);
  const [importantLetters, setImportantLetters] = useState<boolean>(props.libraryBook.importantLetters);
  const [feature, setFeature] = useState<string>(props.libraryBook.feature ? props.libraryBook.feature : '');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);


  function onSubmitHandler(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    props.onUpdated({
      id: props.libraryBook.id,
      bookName: bookName,
      author: author,
      copies: props.libraryBook.copies,
      language: language,
      level: level,
      bigLetters: bigLetters,
      colorBook: colorBook,
      importantLetters: importantLetters,
      feature: feature,
      createdAt: props.libraryBook.createdAt,
      createdBy: props.libraryBook.createdBy,
      updatedAt: props.libraryBook.updatedAt,
      updatedBy: props.libraryBook.updatedBy
    })
  }

  return (
    <SStackForm onSubmit={onSubmitHandler}>
      <Divider />
      <SStackWhite>
        <TextFieldRequired label='Book Name' value={bookName} baseValue={props.libraryBook.bookName} onChange={setBookName} />
        <TextFieldOptional label='Author Name' value={author} baseValue={props.libraryBook.author} onChange={setAuthor} />
        <TextFieldOptional label='Features' value={feature} baseValue={props.libraryBook.feature ? props.libraryBook.feature : ''} onChange={setFeature} />
        <ButtonGroupControl
          label='Language'
          value={language}
          baseValue={props.libraryBook.language}
          options={Object.keys(BookLanguage)}
          onChange={setLanguage} />
        <ButtonGroupControl
          label='Level'
          value={level}
          baseValue={props.libraryBook.level}
          options={Object.keys(BookLevel)}
          onChange={setLevel} />
        <RadioButtonControl label='Big Letters' value={bigLetters} baseValue={props.libraryBook.bigLetters} onChange={setBigLetters} />
        <RadioButtonControl label='Color Book' value={colorBook} baseValue={props.libraryBook.colorBook} onChange={setColorBook} />
        <RadioButtonControl label='Important Letters' value={importantLetters} baseValue={props.libraryBook.importantLetters} onChange={setImportantLetters} />
      </SStackWhite >
      <BottomNavBar>
        <ConfirmationDialog
          open={showConfirmationDialog}
          message={`Are you sure you want to delete book '${props.libraryBook.bookName}'?`}
          onConfirm={() => props.onDelete(props.libraryBook)}
          onCancel={() => setShowConfirmationDialog(false)} />
        <BottomNavAction label="Back" icon={<ArrowBackIosNewIcon />} onClick={props.onBack} />
        {props.libraryBook.id !== -1 && <BottomNavAction label="Delete" icon={<DeleteForeverIcon />} onClick={() => setShowConfirmationDialog(true)} />}
        <BottomNavAction label="Save" icon={<SaveIcon />} type='submit' />
      </BottomNavBar>
      <SnackbarProvider />
    </SStackForm>
  )
}