import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { AccessLevel, UserInfo, defaultDateFormat } from "../../components/AppConstants";
import { BorrowerType, CheckedOutBook } from "./LibraryDtos";
import { SPaper } from "../../components/styled/paper/SPaper";
import { SCardRowText } from "../../components/styled/card/SCardRowText";
import { SCard } from "../../components/styled/card/SCard";

interface LibraryCardProps {
  userInfo: UserInfo
  checkedOutBook: CheckedOutBook
  onExtend: (transactionId: number, dueDate: string) => void
  onReturn: (transactionId: number, dueDate: string, returnDate: string) => void
}

export function LibraryCard(props: LibraryCardProps) {
  const dueDate = DateTime.fromFormat(props.checkedOutBook.transaction.dueDate, defaultDateFormat)
  const checkOutDate = DateTime.fromFormat(props.checkedOutBook.transaction.checkOutDate, defaultDateFormat)
  const { id, userType } = props.checkedOutBook.transaction
  const { bookName } = props.checkedOutBook.book
  const { student, volunteer } = props.checkedOutBook

  const currentDay = DateTime.now().startOf('day')

  const [newDueDate, setNewDueDate] = useState<DateTime>(dueDate.plus({ days: 7 }))
  const [returnDate, setReturnDate] = useState<DateTime>(currentDay)

  const userName = userType === BorrowerType.Student ? student?.firstName + " " + student?.lastName : volunteer?.name;
  const checkoutDateValue = "[ " + checkOutDate.toRelativeCalendar() + " ]  " + checkOutDate.toLocaleString(DateTime.DATE_MED);
  const dueDateValue = "[ " + dueDate.toRelativeCalendar() + " ]  " + dueDate.toLocaleString(DateTime.DATE_MED);
  const dueDateColor = dueDate.diffNow().as('days') > 0 ? "textSecondary" : "error.main";

  return (
    <SPaper>
      <SCard>
        <CardHeader
          title={bookName}
          titleTypographyProps={{ variant: "h6", align: "center", }}
          sx={{ padding: 1 }}
        />
        <Divider />
        <CardContent sx={{ padding: 1, '&:last-child': { pb: 1 } }}>
          <SCardRowText label={userType} value={userName} />
          {userType === BorrowerType.Student &&
            <SCardRowText label="Level" value={student?.level} />
          }
          <SCardRowText label="CheckOut Date" value={checkoutDateValue} />
          <SCardRowText label="Due Date" value={dueDateValue} valueColor={dueDateColor} />
          {props.userInfo.libraryAccess === AccessLevel.ReadWrite &&
            <Stack>
              <Divider variant="fullWidth" sx={{ padding: 1 }} />
              <Stack direction="row" justifyContent="space-between" paddingTop={2} gap={1}>
                <TextField
                  required
                  type="date"
                  size="small"
                  label='Due Date'
                  inputProps={{ min: currentDay.toFormat(defaultDateFormat), max: currentDay.plus({ days: 90 }).toFormat(defaultDateFormat) }}
                  value={newDueDate.toFormat(defaultDateFormat)}
                  onChange={(event) => setNewDueDate(DateTime.fromFormat(event.target.value, defaultDateFormat))}
                />
                <Button variant="outlined" color="secondary" onClick={() => props.onExtend(id, newDueDate.toFormat(defaultDateFormat))}>
                  Extend
                </Button>
              </Stack>
              <Stack direction="row" justifyContent="space-between" paddingTop={2} gap={1}>
                <TextField
                  required
                  type="date"
                  size="small"
                  label='Return Date'
                  inputProps={{ min: currentDay.minus({ days: 90 }).toFormat(defaultDateFormat), max: currentDay.toFormat(defaultDateFormat) }}
                  value={returnDate.toFormat(defaultDateFormat)}
                  onChange={(event) => setReturnDate(DateTime.fromFormat(event.target.value, defaultDateFormat))}
                />
                <Button variant="outlined" color="success" onClick={() => props.onReturn(id, dueDate.toFormat(defaultDateFormat), returnDate.toFormat(defaultDateFormat))}>
                  Return
                </Button>
              </Stack>
            </Stack>
          }
        </CardContent>
      </SCard>
    </SPaper>
  );
}