import { Stack } from '@mui/material';
import { LibraryCard } from './LibraryCard';
import { CheckedOutBook } from './LibraryDtos';
import { UserInfo } from '../../components/AppConstants';

interface LibraryTransactionsProps {
  userInfo: UserInfo
  checkedOutBooks: CheckedOutBook[]
  onExtend: (transactionId: number, dueDate: string) => void
  onReturn: (transactionId: number, dueDate: string, returnDate: string) => void
}

export function LibraryTransactions(props: LibraryTransactionsProps) {

  return (
    <Stack height='100%' gap={2}>
      {props.checkedOutBooks.map((checkedOutBook) =>
        <LibraryCard
          key={checkedOutBook.transaction.id}
          userInfo={props.userInfo}
          checkedOutBook={checkedOutBook}
          onExtend={props.onExtend}
          onReturn={props.onReturn}
        />
      )
      }
    </Stack>
  );
}
