import { Box, CssBaseline, Stack } from '@mui/material';
import { useState } from 'react';
import { PageName, UserInfo } from '../components/AppConstants';
import { Content } from '../components/Content';
import { Header } from '../components/Header';
import { Navigator } from '../components/navi/Navigator';
import Clarity from '@microsoft/clarity';

interface HopePageProps {
  userInfo: UserInfo
  logout: () => void
}

export function HomePage(props: HopePageProps) {
  const [selectedPage, setSelectedPage] = useState<PageName>(PageName.Students)
  const [selectedYear, setSelectedYear] = useState<string>(props.userInfo.year[0])
  const [selectedLevel, setSelectedLevel] = useState<string>(props.userInfo.level[0])
  const [isTest, setIsTest] = useState<boolean>(props.userInfo.isTest)
  const [mobileOpen, setMobileOpen] = useState(false);

  Clarity.identify(props.userInfo.email, '', '', props.userInfo.name);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onPageSelect = (page: PageName, year: string, level: string) => {
    console.log("Selected page: " + page + ", year: " + year + ", level: " + level)
    setSelectedPage(page)
    setSelectedYear(year)
    setSelectedLevel(level)
    handleDrawerToggle()
  }

  return (
    <Stack flexDirection="row" justifyContent="center" sx={{ backgroundImage: "url('images/pattern6.jpg')", minHeight: "100vh" }}>
      <Stack direction="column" bgcolor="#eaeff1" sx={{ minWidth: 300, maxWidth: 600, minHeight: "100vh" }}>
        {/* Dummy box to get the max width for cards in students page */}
        <Box sx={{ width: "100vw" }} />
        <CssBaseline />
        <Navigator
          open={mobileOpen}
          onClose={handleDrawerToggle}
          selectedPage={selectedPage}
          userInfo={props.userInfo}
          handlePageSelect={onPageSelect}
        />
        <Header
          userInfo={props.userInfo}
          selectedPage={selectedPage}
          selectedYear={selectedYear}
          selectedLevel={selectedLevel}
          isTest={isTest}
          onDrawerToggle={handleDrawerToggle} />
        <Content
          userInfo={props.userInfo}
          selectedPage={selectedPage}
          selectedYear={selectedYear}
          selectedLevel={selectedLevel}
          logout={props.logout} />
      </Stack>
    </Stack>
  )
}
