import MenuBookIcon from '@mui/icons-material/MenuBook';
import { CardHeader, Chip, Divider, IconButton, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { AccessLevel, UserInfo } from '../../components/AppConstants';
import { SCard } from '../../components/styled/card/SCard';
import { SCardContent } from '../../components/styled/card/SCardContent';
import { SCardRowChip } from '../../components/styled/card/SCardRowChip';
import { SCardRowText } from '../../components/styled/card/SCardRowText';
import { SPaper } from '../../components/styled/paper/SPaper';
import { BookLevel, LibraryBook } from './LibraryDtos';

interface BookCardProps {
  userInfo: UserInfo
  index: number
  libraryBook: LibraryBook | undefined
  onSelect: (libraryBook: LibraryBook) => void
}

export function BookCard(props: BookCardProps) {
  return (
    <SPaper >
      <SCard>
        {props.index != -1 && <CardHeader
          title={props.libraryBook?.bookName}
          titleTypographyProps={{ variant: "h6", align: "center" }}
          sx={{
            padding: 0,
            paddingLeft: 1,
            paddingRight: 1,
            ".MuiCardHeader-action": {
              alignSelf: "center"
            }
          }}
          avatar={<Chip label={props.index} />}
          action={
            props.userInfo.libraryAccess === AccessLevel.ReadWrite &&
            <IconButton
              onClick={() => props.onSelect(props.libraryBook!)}
              size="small"
              color="primary"
              sx={{
                height: "100%",
                margin: 1,
                bgcolor: grey[200],
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "white"
                },

              }}>
              <MenuBookIcon />
            </IconButton>
          }
        />}
        {props.index != -1 && <Divider />}
        <SCardContent>
          <SCardRowText label="Author" value={props.libraryBook?.author} />
          <SCardRowText label="Language" value={props.libraryBook?.language} />
          {props.libraryBook?.feature && <SCardRowText label="Feature" value={props.libraryBook?.feature} />}
          {props.libraryBook?.level && props.libraryBook.level !== "NA" &&
            <SCardRowChip
              label='Difficulty'
              value={props.libraryBook?.level}
              color={props.libraryBook?.level === BookLevel.Easy ? 'success' : props.libraryBook?.level === BookLevel.Medium ? 'secondary' : 'warning'} />}

          {(props.libraryBook?.bigLetters || props.libraryBook?.colorBook || props.libraryBook?.importantLetters) &&
            <Stack>
              <Divider sx={{ paddingTop: 1 }} />
              <Stack direction="row" gap={1} paddingTop={1} useFlexGap flexWrap="wrap" justifyContent="right">
                {props.libraryBook?.bigLetters && <Chip variant='outlined' label="Big Letters" color="info" />}
                {props.libraryBook?.colorBook && <Chip variant='outlined' label="Color Book" color="success" />}
                {props.libraryBook?.importantLetters && <Chip variant='outlined' label="Important Letters" color="primary" />}
              </Stack>
            </Stack>}
        </SCardContent>
      </SCard>
    </SPaper >
  );
}
