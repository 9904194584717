import MenuBookIcon from '@mui/icons-material/MenuBook';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Backdrop, Badge, Box, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { AccessLevel, UserInfo } from '../../components/AppConstants';
import { LibraryBooks } from './LibraryBooks';
import { LibraryCheckout } from './LibraryCheckout';
import { CheckedOutBook, LibraryState, LibraryStats, UpdateCheckoutStatusRequest } from './LibraryDtos';
import { LibraryStatsPage } from './LibraryStatsPage';
import { LibraryTransactions } from './LibraryTransactions';

interface LibraryPageProps {
  userInfo: UserInfo
  selectedYear: string
  logout: () => void
}

export function LibraryPage(props: LibraryPageProps) {
  const [backdrop, setBackdrop] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false)
  const [selectedState, setSelectedState] = useState<LibraryState>(LibraryState.Summary);
  const [libraryStats, setLibraryStats] = useState<LibraryStats | undefined>(undefined);
  const [loadingStats, setLoadingStats] = useState<boolean>(true)
  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true)
  const [checkedOutBooks, setCheckedOutBooks] = useState<CheckedOutBook[]>([]);
  const [booksCount, setBooksCount] = useState<number>(0);

  async function loadStats() {
    try {
      setLoadingStats(true)
      // setBackdrop(true)
      console.log("api/books/stats")
      const response = await fetch("api/books/stats")
      if (response.ok) {
        const result: LibraryStats = await response.json();
        setLibraryStats(result)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No library stats found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setLoadingStats(false)
    setBackdrop(false)
  }

  async function loadTransactions() {
    try {
      setLoadingTransactions(true)
      // setBackdrop(true)
      console.log("api/books/transactions")
      const response = await fetch("api/books/transactions")
      if (response.ok) {
        const result: CheckedOutBook[] = await response.json();
        console.log(result)
        setCheckedOutBooks(result)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No transactions found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setLoadingTransactions(false)
    setBackdrop(false)
  }

  useEffect(() => {
    loadStats();
    loadTransactions();
  }, [refresh])

  async function extendHandler(transactionId: number, dueDate: string) {
    // setBackdrop(true)
    try {
      const updateStatusReq: UpdateCheckoutStatusRequest = {
        transactionId: transactionId,
        dueDate: dueDate,
        checkInDate: null
      }

      const response = await fetch("api/books/transactions", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateStatusReq)
      })

      if (response.ok || response.status === 201) {
        console.log("Library book extended successfully.")
        enqueueSnackbar(
          'Library book extended successfully.',
          { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
        setRefresh(!refresh)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Libary book extention failed.")
        enqueueSnackbar('Libary book extention failed.', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          autoHideDuration: 3000
        })
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  async function returnHandler(transactionId: number, dueDate: string, returnDate: string) {
    // setBackdrop(true)
    try {
      const updateStatusReq: UpdateCheckoutStatusRequest = {
        transactionId: transactionId,
        dueDate: dueDate,
        checkInDate: returnDate
      }

      const response = await fetch("api/books/transactions", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateStatusReq)
      })

      if (response.ok || response.status === 201) {
        console.log("Library book returned successfully.")
        enqueueSnackbar(
          'Library book returned successfully.',
          { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
        setRefresh(!refresh)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Libary book return failed.")
        enqueueSnackbar('Libary book return failed.', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          autoHideDuration: 3000
        })
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  return (
    <Stack height='100%'>
      <SnackbarProvider />
      {/* This Backdrop extend the container to max screen height.
      To fix the BattomBar position issues in android browser due to auto url bar hiding */}
      <Backdrop open={backdrop} />
      <Box sx={{ width: '100%', bgcolor: "background.paper" }} position="static">
        <Tabs
          centered
          value={selectedState}
          onChange={(_event, newValue) => setSelectedState(newValue)}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab value={LibraryState.Summary} label="Summary" icon={<SummarizeIcon />} sx={{ textTransform: "unset !important" }} />
          {props.userInfo.libraryAccess === AccessLevel.ReadWrite &&
            <Tab value={LibraryState.CheckOut} label="Checkout" icon={<ShoppingCartIcon />} sx={{ textTransform: "unset !important" }} />
          }
          <Tab
            value={LibraryState.Books}
            label="Books"
            icon={
              <Badge badgeContent={booksCount} color="primary">
                <MenuBookIcon />
              </Badge>}
            sx={{ textTransform: "unset !important" }} />
        </Tabs>
      </Box>
      {selectedState === LibraryState.CheckOut &&
        <LibraryCheckout
          userInfo={props.userInfo}
          selectedYear={props.selectedYear}
          onBackClick={() => {
            setSelectedState(LibraryState.Summary)
            setRefresh(!refresh)
          }}
          logout={props.logout} />
      }
      {selectedState === LibraryState.Books &&
        <LibraryBooks
          userInfo={props.userInfo}
          onFilterChange={(booksCount) => setBooksCount(booksCount)}
          onBackClick={() => {
            setSelectedState(LibraryState.Summary)
          }}
          logout={props.logout}
        />
      }
      {selectedState === LibraryState.Summary &&
        <Fragment>
          <Stack height='100%' padding={2} gap={2}>
            {loadingStats &&
              <Stack>
                <Skeleton variant="rectangular" height={130} />
              </Stack>
            }
            {!loadingStats && libraryStats &&
              <LibraryStatsPage libraryStats={libraryStats} />
            }
            {loadingTransactions &&
              <Stack gap={1}>
                <Skeleton variant="rectangular" height={305} />
                <Skeleton variant="rectangular" height={305} />
                <Skeleton variant="rectangular" height={305} />
              </Stack>
            }
            {!loadingTransactions &&
              <LibraryTransactions
                userInfo={props.userInfo}
                checkedOutBooks={checkedOutBooks}
                onExtend={extendHandler}
                onReturn={returnHandler}
              />
            }
          </Stack>
        </Fragment>
      }
    </Stack>
  );
}
