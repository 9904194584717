import { Person } from '@mui/icons-material';
import { CardHeader, Chip, Divider, IconButton, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ExamPattern } from '../../components/AppConstants';
import { GridEntryMultipleValues } from '../../components/custom/GridEntryMultipleValues';
import { SCard } from '../../components/styled/card/SCard';
import { SCardContent } from '../../components/styled/card/SCardContent';
import { SPaper } from '../../components/styled/paper/SPaper';
import { ExamSetupCardEntry } from './ExamSetupCardEntry';
import { SCardRowText } from '../../components/styled/card/SCardRowText';

interface ExamSetupCardProps {
  index: number
  examPattern: ExamPattern
  onSelect: () => void
}

export function ExamSetupCard(props: ExamSetupCardProps) {

  return (
    <SPaper >
      <SCard>
        <CardHeader
          title={props.examPattern.name}
          titleTypographyProps={{ variant: "body1", align: "center" }}
          sx={{ padding: 1 }}
          avatar={<Chip label={props.index} />}
          action={
            <IconButton
              onClick={() => props.onSelect()}
              size="small"
              color="primary"
              sx={{
                margin: 1,
                bgcolor: grey[200],
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "white"
                }
              }}>
              <Person />
            </IconButton>
          }
        />
        <Divider />
        <Divider />
        <SCardContent>
          <Stack gap={1}>
            <GridEntryMultipleValues label="Classes" values={props.examPattern.levels.length == 12 ? ["All levels"] : props.examPattern.levels} />
            <SCardRowText label="Attendance" value={props.examPattern.attendancePercentage + "%"} sx={{ paddingLeft: 4 }} />
            <SCardRowText label="Homework" value={props.examPattern.homeworkPercentage + "%"} sx={{ paddingLeft: 4 }} />
            <ExamSetupCardEntry examPatternCategories={props.examPattern.categories.ClassPerformance} />
            <ExamSetupCardEntry examPatternCategories={props.examPattern.categories.Project} />
            <ExamSetupCardEntry examPatternCategories={props.examPattern.categories.Exam} />
          </Stack>
        </SCardContent>
      </SCard>
    </SPaper >
  );
}
