import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import PreviewIcon from '@mui/icons-material/Preview';
import SendIcon from '@mui/icons-material/Send';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { ExamScore, isValidEmail } from '../../components/AppConstants';
import { BottomNavAction } from '../../components/custom/BottomNavAction';
import { BottomNavBar } from '../../components/custom/BottomNavBar';
import { SBackdrop } from '../../components/styled/backdrop/SBackdrop';
import { SStackFull } from '../../components/styled/stack/SStackFull';

interface ExamEmailProps {
  examScores: ExamScore[],
  onBack: (refresh: boolean, message: string) => void
  logout: () => void
}

export function ExamEmail(props: ExamEmailProps) {
  const [backdrop, setBackdrop] = useState(false);
  const [emailMap, setEmailMap] = useState<Map<string, boolean>>(new Map<string, boolean>(props.examScores.map((examScore) => [examScore.studentName, isInvalid(examScore) ? false : true])))

  async function onPreview() {
    try {
      setBackdrop(true)
      const studentIds = props.examScores
        .filter((examScore) => emailMap.get(examScore.studentName))
        .map((examScore) => examScore.studentId);
      const response = await fetch(`api/exam/preview`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(studentIds)
      })
      if (response.ok) {
        console.log("Email sent successfully.")
        enqueueSnackbar('Email sent successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Failed to send email. " + response.status + " " + response.statusText)
        enqueueSnackbar('Failed to send email.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Failed to send email.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  async function onSend() {
    try {
      setBackdrop(true)
      const studentIds = props.examScores
        .filter((examScore) => emailMap.get(examScore.studentName))
        .map((examScore) => examScore.studentId);
      const response = await fetch(`api/exam/email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(studentIds)
      })
      if (response.ok) {
        console.log("Email sent successfully.")
        enqueueSnackbar('Email sent successfully.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Failed to send email. " + response.status + " " + response.statusText)
        enqueueSnackbar('Failed to send email.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
      }
    } catch (error) {
      console.error("An error occurred:", error);
      enqueueSnackbar('Failed to send email.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })
    }
    setBackdrop(false)
  }

  function handleChange(examScore: ExamScore, checked: boolean): void {
    setEmailMap((prev) => new Map(prev).set(examScore.studentName, checked))
  }

  function isInvalid(examScore: ExamScore): boolean {
    return isEmailsAreInvalid(examScore) || examScore.id === -1;
  }

  function getFormattedName(examScore: ExamScore): string {
    const emailValidation = isEmailsAreInvalid(examScore) ? " (Invalid Emails)" : "";
    const scoreValidation = examScore.id === -1 ? " (Score not saved)" : "";
    return `${examScore.studentName} ${scoreValidation} ${emailValidation}`;
  }

  function isEmailsAreInvalid(examScore: ExamScore) {
    return !isValidEmail(examScore.fatherEmail) && !isValidEmail(examScore.motherEmail);
  }


  return (
    <SStackFull space={0}>
      <SBackdrop open={backdrop} />
      <SnackbarProvider />
      <SStackFull>
        <FormGroup>
          {props.examScores.map((examScore) =>
            <FormControlLabel
              key={examScore.studentId}
              control={
                <Checkbox
                  checked={emailMap.get(examScore.studentName)}
                  onChange={(event) => handleChange(examScore, event.target.checked)}
                />
              }
              label={getFormattedName(examScore)}
              disabled={isInvalid(examScore)} />)
          }
        </FormGroup>
      </SStackFull>
      <BottomNavBar>
        <BottomNavAction label="Back" icon={<ArrowBackIosNewIcon />} onClick={() => props.onBack(false, "")} />
        <BottomNavAction label="Preview to me" icon={<PreviewIcon />} onClick={onPreview} />
        <BottomNavAction label="Send to parents" icon={<SendIcon />} onClick={onSend} />
      </BottomNavBar>
    </SStackFull>
  );
}