import EmailIcon from '@mui/icons-material/Email';
import { Button, ButtonGroup, Fab, Skeleton, Stack } from '@mui/material';
import { SnackbarKey, SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { ExamScore } from '../../components/AppConstants';
import { SBackdrop } from '../../components/styled/backdrop/SBackdrop';
import { SchoolDay } from '../studentDay/Dtos';
import { ExamCard } from './ExamCard';
import { ExamEdit } from './ExamEdit';
import { ExamEmail } from './ExamEmail';

interface ExamPageProps {
  selectedYear: string,
  selectedLevel: string,
  logout: () => void
}

export function ExamPage(props: ExamPageProps) {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [backdrop, setBackdrop] = useState(false);
  const [activeDay, setActiveDay] = useState<SchoolDay | undefined>(undefined)
  const [examDays, setExamDays] = useState<SchoolDay[]>([])
  const [examScores, setExamScores] = useState<ExamScore[]>([])
  const [selected, setSelected] = useState<ExamScore | undefined>(undefined)
  const [email, setEmail] = useState<boolean>(false)

  async function loadExamDays(level: string) {
    try {
      console.log("Fetching exam days for level: " + level + " day: " + activeDay?.day)
      const dayFilter = activeDay?.day ? `&day=${activeDay.id}` : ""
      setBackdrop(true)
      const response = await fetch(`api/exam/days?year=${props.selectedYear}&level=${level}${dayFilter}`)
      if (response.ok) {
        const receivedDays: SchoolDay[] = await response.json();
        setExamDays(receivedDays)
        setActiveDay(receivedDays.find((schoolDay) => schoolDay.active))
        console.log("ActiveDay: " + receivedDays.find((schoolDay) => schoolDay.active)?.day)
        setLoading(false)
      } else if (response.status === 401) {
        props.logout()
      } else {
        showErrorMessage(await response.text())
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  const action = (snackbarId: SnackbarKey | undefined) => (
    <>
      <button onClick={() => { closeSnackbar(snackbarId) }}>
        Dismiss
      </button>
    </>
  );

  function showErrorMessage(message: string) {
    console.log(message)
    enqueueSnackbar(message, {
      action,
      variant: 'error',
      persist: true,
      anchorOrigin: { vertical: 'top', horizontal: 'center' }
    })
  }

  useEffect(() => {
    loadExamDays(props.selectedLevel);
  }, [props.selectedLevel])

  async function loadExamScore(level: string, day: number) {
    try {
      console.log(`Fetching exam score for level: ${level} day: ${day}`)
      setBackdrop(true)
      const response = await fetch(`api/exam/students?level=${level}&day=${day}`)
      if (response.ok) {
        const result: ExamScore[] = await response.json();
        setExamScores(result)
      } else if (response.status === 401) {
        props.logout()
      } else {
        showErrorMessage(await response.text())
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  useEffect(() => {
    if (activeDay) {
      loadExamScore(props.selectedLevel, activeDay?.id);
    }
  }, [props.selectedLevel, activeDay?.id, refresh])


  function onSelect(examScore: ExamScore): void {
    setSelected(examScore)
  }

  function onBack(forceRefresh: boolean, message: string): void {
    setSelected(undefined)
    setEmail(false)
    if (forceRefresh) {
      setRefresh(!refresh)
    }
    if (message) {
      enqueueSnackbar(message, {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        autoHideDuration: 3000,
      })
    }
  }

  return (
    <Stack height='100%'>
      <SBackdrop open={backdrop} />
      <SnackbarProvider />
      {loading &&
        <Stack gap={2} padding={2}>
          <Skeleton variant="rectangular" height={130} />
          <Skeleton variant="rectangular" height={130} />
          <Skeleton variant="rectangular" height={130} />
        </Stack>
      }
      {!loading && !selected && !email &&
        <Fragment>
          <Stack gap={2} padding={2} paddingBottom={10}>
            <Stack direction="row" justifyContent="center">
              <ButtonGroup variant='outlined'>
                {examDays.map((examDay) =>
                  <Button
                    key={examDay.id}
                    color='info'
                    variant={activeDay?.id === examDay.id ? 'contained' : 'outlined'}
                    onClick={() => setActiveDay(examDay)}
                  >
                    {examDay.term}
                  </Button>)
                }
              </ButtonGroup>
            </Stack>
            {examScores.map((examScore, index) =>
              <ExamCard key={examScore.studentId} index={index + 1} examScore={examScore} onSelect={onSelect} />)}
          </Stack>
          <Fab
            color="secondary"
            sx={{ position: 'fixed', bottom: '16px', left: '50%', transform: 'translateX(-50%)' }}
            onClick={(_event) => { setEmail(true) }}
          >
            <EmailIcon />
          </Fab>
        </Fragment>
      }
      {!loading && selected && !email &&
        <ExamEdit
          term={activeDay?.term}
          examScore={selected}
          onBack={onBack}
          logout={props.logout} />
      }
      {!loading && !selected && email &&
        <ExamEmail
          examScores={examScores}
          onBack={onBack}
          logout={props.logout} />
      }
    </Stack>
  );
}