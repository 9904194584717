import { Box, Stack, Typography } from "@mui/material";
import { AndroidSwitch } from "./AndroidSwitch";
import { StyledToggleButtonGroup } from "./ButtonGroupControlMiddle";

interface RadioButtonControlProps {
  label: string
  value: boolean
  baseValue: boolean
  onChange: (newValue: boolean) => void
}

export function RadioButtonControl(props: RadioButtonControlProps) {
  return (
    <Box border={1} borderRadius={1} borderColor='rgba(0, 0, 0, 0.23)'>
      <Stack gap={1} direction="row" justifyContent="space-between" alignItems="center" paddingLeft={1} >
        <Typography variant="subtitle2" >{props.label}</Typography>
        <StyledToggleButtonGroup
          sx={{ justifyContent: 'right' }}
          color="primary"
          value={props.value}
          exclusive
          onChange={(_event, newValue) => props.onChange(newValue)}
        >
          <AndroidSwitch studentId={-1} isSelected={props.value} onChange={(id, value) => props.onChange(value)} />
        </StyledToggleButtonGroup>
      </Stack>
    </Box>
  )
}