import { FormControlLabel, Stack, Typography } from "@mui/material";
import { AndroidSwitch } from "../../components/custom/AndroidSwitch";
import { SPaper } from "../../components/styled/paper/SPaper";
import { SchoolDay, TeacherDayStatus } from "./Dtos";

interface TeacherSwitchProps {
  index: number
  selectedDay: SchoolDay
  selectedStatus: string
  teacherDayStatus: TeacherDayStatus
  onTeacherSwitchChange: (teacherId: Number, isSelected: boolean) => void
}

export function TeacherSwitch(props: TeacherSwitchProps) {
  return (
    <SPaper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" padding={1}>
        <Typography variant="h6" color="text.secondary">{props.index}. {props.teacherDayStatus.teacherName}</Typography>
        <FormControlLabel sx={{ margin: 0 }}
          control={<AndroidSwitch studentId={props.teacherDayStatus.teacherId} isSelected={props.teacherDayStatus.status === props.selectedStatus} onChange={props.onTeacherSwitchChange} />}
          label=""
        />
      </Stack>
    </SPaper>
  );
}