import RefreshIcon from '@mui/icons-material/Refresh';
import { Backdrop, CircularProgress, Fab, Skeleton, Stack, Typography } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';

interface AppLogProps {
  logout: () => void
}

export function AppLog(props: AppLogProps) {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [backdrop, setBackdrop] = useState(false);
  const [lines, setLines] = useState<string[]>([])

  async function loadLines() {
    try {
      const urlToLoad = "api/log";
      console.log(urlToLoad)
      setBackdrop(true)
      const response = await fetch(urlToLoad)
      if (response.ok) {
        const result: string[] = await response.json();
        setLines(result)
        setLoading(false)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No library books found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
    setBackdrop(false)
  }

  useEffect(() => {
    loadLines()
  }, [refresh])


  return (
    <Stack height='100%'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackbarProvider />
      {loading &&
        <Stack gap={1} padding={1}>
          <Skeleton variant="rectangular" height={322} />
        </Stack>
      }
      {!loading &&
        <Stack padding={1}>
          {lines.map((line, index) => <Typography key={index} paragraph component="div" m={0}>
            {line}
          </Typography>)}
        </Stack>
      }
      {!loading &&
        <Fab color="secondary" sx={{ position: 'fixed', bottom: '16px', left: '50%', transform: 'translateX(-50%)' }} onClick={(_event) => setRefresh(!refresh)}>
          <RefreshIcon />
        </Fab>
      }
    </Stack>
  );
}