import { MenuItem, TextField } from "@mui/material";
import { getColorRequired } from "./TextFieldRequired";

interface TextFieldRequiredSelectProps {
  label: string
  value: string
  baseValue: string
  options: string[]
  onChange: (newValue: string) => void
}

export function TextFieldRequiredSelect(props: TextFieldRequiredSelectProps) {
  return (
    <TextField
      required
      select
      fullWidth
      size="small"
      label={props.label}
      value={props.value}
      SelectProps={{ MenuProps: { disableScrollLock: true } }}
      onChange={(event) => props.onChange(event.target.value)}
    >
      {props.options.map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)}
    </TextField>
  )
}