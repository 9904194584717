import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Autocomplete, Button, ButtonGroup, Divider, Fab, Skeleton, Stack, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { enqueueSnackbar } from 'notistack';
import { FormEvent, Fragment, useEffect, useState } from "react";
import { UserInfo, defaultDateFormat } from '../../components/AppConstants';
import { DropDown } from '../../components/custom/DropDown';
import { Student } from '../student/StudentPage';
import { BookCard } from './BookCard';
import { BorrowerType, CheckOutRequest, LibraryBook, Volunteer } from './LibraryDtos';

interface LibraryCheckoutProps {
  userInfo: UserInfo
  selectedYear: string
  onBackClick: () => void
  logout: () => void
}

export function LibraryCheckout(props: LibraryCheckoutProps) {
  const [loading, setLoading] = useState<boolean>(true)
  const [students, setStudents] = useState<Student[]>([])
  const [volunteers, setVolunteers] = useState<Volunteer[]>([])
  const [libraryBooks, setLibraryBooks] = useState<LibraryBook[]>([])
  const [borrowerType, setBorrowerType] = useState<BorrowerType>(BorrowerType.Student)
  const [selectedLevel, setSelectedLevel] = useState<string | undefined>(props.userInfo.level[0])
  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>(undefined)
  const [selectedVolunteer, setSelectedVolunteer] = useState<Volunteer | undefined>(undefined)
  const [selectedBook, setSelectedBook] = useState<LibraryBook | undefined>(undefined)

  const currentDay = DateTime.now().startOf('day')
  const [checkOutDate, setCheckOutDate] = useState<DateTime>(currentDay)
  const [dueDate, setDueDate] = useState<DateTime>(currentDay.plus({ days: 7 }))

  async function loadBooks() {
    try {
      const urlToLoad = "api/books";
      console.log(urlToLoad)
      const response = await fetch(urlToLoad)
      if (response.ok) {
        const result: LibraryBook[] = await response.json();
        setLibraryBooks(result)
        setLoading(false)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No library books found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  async function loadVolunteers() {
    try {
      const urlToLoad = "api/volunteers";
      console.log(urlToLoad)
      const response = await fetch(urlToLoad)
      if (response.ok) {
        const result: Volunteer[] = await response.json();
        setVolunteers(result)
      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("No volunteers found.")
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  useEffect(() => {
    loadBooks()
    loadVolunteers()
  }, [])

  async function loadStudents(level: string | null | undefined) {
    try {
      console.log("loadStudents: " + level)
      setSelectedStudent(undefined)
      if (level !== null && level !== undefined) {
        const studentsApiUrl = `api/students?year=${props.selectedYear}&level=${level}`;
        console.log(studentsApiUrl)
        const response = await fetch(studentsApiUrl)
        if (response.ok) {
          const result: Student[] = await response.json();
          setStudents(result)
          console.log("Students loaded: " + result.length)
        } else if (response.status === 401) {
          props.logout()
        } else {
          console.log("No students found.")
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  useEffect(() => {
    console.log("useEffect loadStudents for " + selectedLevel)
    loadStudents(selectedLevel);
  }, [selectedLevel])


  function onSubmitHandler(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    console.log("onSubmitHandler")
    checkOutBook()
  }

  async function checkOutBook(): Promise<void> {
    try {
      const checkOutRequest: CheckOutRequest = {
        bookId: selectedBook?.id ?? -1,
        userType: borrowerType,
        studentId: selectedStudent?.id ?? null,
        volunteerId: selectedVolunteer?.id ?? null,
        checkOutDate: checkOutDate.toFormat(defaultDateFormat),
        dueDate: dueDate.toFormat(defaultDateFormat),
      }

      console.log(JSON.stringify(checkOutRequest))

      const response = await fetch("api/books/transactions", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(checkOutRequest)
      })

      if (response.ok || response.status === 201) {
        console.log("Library book checkedout successfully.")
        enqueueSnackbar(
          'Library book checkedout successfully.',
          { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 })

      } else if (response.status === 401) {
        props.logout()
      } else {
        console.log("Libary book checkout failed.")
        enqueueSnackbar('Libary book checkout failed.', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          autoHideDuration: 3000
        })
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  return (
    <Stack height='100%' component='form' onSubmit={onSubmitHandler}>
      <Divider />
      {loading &&
        <Stack gap={2} padding={1} height="100%">
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rectangular" height={60} />
        </Stack>
      }
      {!loading &&
        <Stack height='100%' padding={2} gap={2} bgcolor="white">
          <Stack direction="row" justifyContent="center">
            <ButtonGroup variant='outlined'>
              <Button color='info' variant={borrowerType === BorrowerType.Student ? 'contained' : 'outlined'} onClick={() => setBorrowerType(BorrowerType.Student)}>{BorrowerType.Student}</Button>
              <Button color='info' variant={borrowerType === BorrowerType.Volunteer ? 'contained' : 'outlined'} onClick={() => setBorrowerType(BorrowerType.Volunteer)}>{BorrowerType.Volunteer}</Button>
            </ButtonGroup>
          </Stack>
          <Autocomplete
            autoHighlight
            id="library-books-name"
            options={libraryBooks}
            value={selectedBook}
            clearOnBlur
            onChange={(_event, newValue) => { setSelectedBook(newValue === null ? undefined : newValue) }}
            getOptionLabel={(option) => option.bookName}
            renderInput={(params) => <TextField {...params} label="Book Name" required />}
            renderOption={(props, option) => <li {...props} key={option.id}>{option.bookName} - {option.author}</li>}
          />
          {borrowerType === BorrowerType.Student &&
            <Fragment>
              <DropDown
                label="Student Level"
                selectedValue={selectedLevel}
                allValues={props.userInfo.level}
                onChange={(value) => setSelectedLevel(value)} />
              <Autocomplete
                autoHighlight
                id="library-checkout-student-names"
                options={students}
                value={selectedStudent}
                onChange={(_event, newValue) => { setSelectedStudent(newValue === null ? undefined : newValue) }}
                getOptionLabel={(option) => option.firstName + " " + option.lastName}
                renderInput={(params) => <TextField {...params} label="Student Name" required />}
                renderOption={(props, option) => <li {...props} key={option.id.toString()}>{option.firstName} {option.lastName}</li>}
              />
            </Fragment>
          }
          {borrowerType === BorrowerType.Volunteer &&
            <Autocomplete
              autoHighlight
              id="library-checkout-volunteer-names"
              options={volunteers}
              value={selectedVolunteer}
              onChange={(_event, newValue) => { setSelectedVolunteer(newValue === null ? undefined : newValue) }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Volenteer Name" required />}
              renderOption={(props, option) => <li {...props} key={option.id.toString()}>{option.name}</li>}
            />
          }
          <Stack direction={"row"} gap={1}>
            <TextField
              required
              fullWidth
              type="date"
              size="small"
              label='Issue Date'
              inputProps={{ min: currentDay.minus({ days: 90 }).toFormat(defaultDateFormat), max: currentDay.toFormat(defaultDateFormat) }}
              value={checkOutDate.toFormat(defaultDateFormat)}
              onChange={(event) => setCheckOutDate(DateTime.fromFormat(event.target.value, defaultDateFormat))}
            />
            <TextField
              required
              fullWidth
              type="date"
              size="small"
              label='Due Date'
              inputProps={{ min: currentDay.toFormat(defaultDateFormat), max: currentDay.plus({ days: 90 }).toFormat(defaultDateFormat) }}
              value={dueDate.toFormat(defaultDateFormat)}
              onChange={(event) => setDueDate(DateTime.fromFormat(event.target.value, defaultDateFormat))}
            />
          </Stack>

          {selectedBook && <BookCard userInfo={props.userInfo} index={-1} libraryBook={selectedBook} onSelect={() => console.log("Not applicable")} />}
          <Stack direction="row" justifyContent="center">
            <Fab
              color="secondary"
              aria-label="add"
              disabled={selectedBook === undefined
                || (borrowerType === BorrowerType.Student && selectedStudent === undefined)
                || (borrowerType === BorrowerType.Volunteer && selectedVolunteer === undefined)}
              type='submit'>
              <ShoppingCartIcon />
            </Fab>
          </Stack>
        </Stack >
      }
    </Stack >
  );
}
