import { Stack, ToggleButton, ToggleButtonGroup, styled } from "@mui/material";

interface ButtonGroupControlMiddleProps {
  value: string
  options: string[]
  onChange: (newValue: string) => void
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButton-root.Mui-selected': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
}));

export function ButtonGroupControlMiddle(props: ButtonGroupControlMiddleProps) {
  return (
    <Stack direction="row" justifyContent="center">
      <StyledToggleButtonGroup
        color="primary"
        value={props.value}
        exclusive
        onChange={(_event, newValue) => {
          if (newValue !== null) {
            props.onChange(newValue)
          }
        }}
      >
        {props.options.map((key) => <ToggleButton key={key} value={key} sx={{ textTransform: "unset !important" }}>{key}</ToggleButton>)}
      </StyledToggleButtonGroup>
    </Stack>
  )
}