import { Box, Stack, ToggleButton, Typography } from "@mui/material";
import { StyledToggleButtonGroup } from "./ButtonGroupControlMiddle";

interface ButtonGroupControlProps {
  label: string
  value: string
  baseValue: string
  options: string[]
  onChange: (newValue: string) => void
}

export function ButtonGroupControl(props: ButtonGroupControlProps) {
  const newValue = props.value === props.baseValue ? "success.main" : "secondary.main";
  return (
    <Box border={1} borderRadius={1} borderColor='rgba(0, 0, 0, 0.23)'>
      <Stack gap={1} direction="row" justifyContent="space-between" alignItems="center" margin={1} >
        <Typography variant="subtitle2">{props.label}</Typography>
        <StyledToggleButtonGroup
          sx={{ justifyContent: 'right' }}
          size="small"
          color="primary"
          value={props.value}
          exclusive
          onChange={(_event, newValue) => props.onChange(newValue)}
        >
          {props.options.map((key) => <ToggleButton size="small" key={key} value={key} sx={{ textTransform: "unset !important" }}>{key}</ToggleButton>)}
        </StyledToggleButtonGroup>
      </Stack>
    </Box>
  )
}