import { Card } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

interface SCardProps {
  children: ReactNode
  sx?: CSSProperties;
}

export function SCard(props: SCardProps) {
  return (
    <Card sx={{ ...({ borderRadius: 4 }), ...(props.sx) }}>
      {props.children}
    </Card>
  );
}