import { Chip, Stack, Typography } from '@mui/material';

interface SCardRowChipProps {
  label: string
  value: string
  color: 'success' | 'warning' | 'error' | 'info' | 'primary' | 'secondary'
}

export function SCardRowChip(props: SCardRowChipProps) {
  return (
    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
      <Typography variant='body1' color="textSecondary">
        {props.label}
      </Typography>
      <Chip size="small" variant='outlined' color={props.color} label={props.value} />
    </Stack>
  );
}